<template>
  <div class="common_bottom">
    <span>技术支持： 广东金质信息技术有限公司</span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss'>
  .common_bottom {
    width: 100%;
    min-width: 1200px;
    text-align: center;
    font-size: 14px;
    color: #909399;
    line-height: 20px;
    margin: 30px 0;

    & >span {
      margin-right: 10px;
    }
  }
</style>