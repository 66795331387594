<template>
  <div id="layout">
    <div class="layout_container">
      <router-view></router-view>
    </div>
    <CommonBottom />
  </div>
</template> 

<script>
import CommonBottom from './components/CommonBottom.vue'
export default {
  components: {
    CommonBottom,
  }
}
</script>

<style lang='scss'>
  #layout {
    min-height: 100vh;

    .layout_container {
      min-height: calc(100vh - 80px);
    }
  }
</style>